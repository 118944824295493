// import * as ApolloPkg from '@apollo/client';
// const { gql } = ApolloPkg;

const NEWEST_POSTS_FRAGMENT = `
    id
    title
    slug
    excerpt
    date
    featuredImage {
        node {
            mediaDetails {
                sizes(include: [MEDIUM_LARGE]) {
                    name
                    sourceUrl
                    width
                    mimeType
                }
            }
            mediaItemUrl
        }
    }
`;

// const POST_FIELDS_FRAGMENT = gql`
//     fragment PostFields on Post {
//         id
//         title
//         blocks {
//             saveContent
//         }
//         seo {
//             title
//             metaDesc
//             focuskw
//             opengraphDescription
//             opengraphTitle
//             opengraphDescription
//             opengraphImage {
//                 altText
//                 sourceUrl
//                 srcSet
//             }
//             opengraphSiteName
//             opengraphPublishedTime
//             opengraphModifiedTime
//             cornerstone
//             schema {
//                 pageType
//                 articleType
//             }
//             readingTime
//         }
//     }
// `;

export default NEWEST_POSTS_FRAGMENT;