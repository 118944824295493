import { Transition } from '@headlessui/react';

const Skeleton = () => (
    <Transition
        show={true}
        enter="transition ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        <div className="animate-pulse">
            <div className="skeleton-animation h-40 rounded-10"></div>
            <div className="py-5 px-7 flex flex-col gap-5">
                <div className="skeleton-animation h-5 w-3/4"></div>
                <div className="skeleton-animation h-5 w-1/2"></div>
            </div>
        </div>
    </Transition>
);

export default Skeleton